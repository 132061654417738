.all {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 90%;
    display: flex;
    justify-content: center;
    background-color: #04c38f;
    padding: 4px;
    margin-bottom: 1rem;
    border-radius: 5px;
}

.container .containAll {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
}

.container .containAll .details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.container .containAll .details .detailConsultant {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.container .containAll .details .detailConsultant .assign {
    outline: none;
    background-color: #143d66;
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 30px;
}

.container .containAll .details .detailConsultant .button {
    border: none;
    outline: none;
    background-color: #fff;
    text-align: left;
    color: #143d66;
    text-decoration: underline;
    font-weight: 600;
    width: 80%;
}
.container .containAll .details .detailConsultant .button span {
    font-style: italic;
    font-weight: 500;
}

.container .containAll .details .detailConsultant .button:hover {
    cursor: pointer;
}

.container .containAll .details .detailConsultant .spaceBetween {
    display: flex;
    justify-content: space-between;
}

.container .containAll .details .detailConsultant .spaceBetween h6 {
    color: #143d66;
    font-weight: 600;
}

.container .containAll .details .detailConsultant .spaceBetween p {
    color: #143d66;
    margin: 0;
}

.red {
    color: red;
}

.hidden {
    display:none;
}