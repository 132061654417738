.container {
    width: 95%;
    display: flex;
    margin: 20px 0 0 0;
    background-color: #fff;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
}

.container .containDetails {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 0;
}

.container .containDetails .title {
    color: #143D66;
    font-weight: 600;
    font-size: 23px;
}

.container .containDetails .barre {
    height: 5px;
    width: 25px;
    border-radius: 5px;
    background: #143d66;
    margin-bottom: 10px;
}

.container .containDetails .divinput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.container .containDetails .divinput label {
    margin: 0;
    color: #143D66;
    font-weight: 600;
}

.container .containDetails .divinput input {
    width: 30%;
    border: none;
    background-color: #fff;
    color: #143D66;
    text-align: center;
}

.container .containDetails .divinput button {
    text-decoration: none;
    background-color: #fff;
    border: none;
}

.container .containDetails .divinputTJM {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.container .containDetails .divinputTJM label {
    margin: 0 1rem 0 0;
    color: #143D66;
    font-weight: 600
}

.container .containDetails .divinputTJM input {
    width: 30%;
    border: none;
    margin-right: 10px;
    background-color: #fff;
    color: #143D66;
    text-align: center;
}

.container .containDetails .divinputTJM button {
    text-decoration: none;
    background-color: #fff;
    border: none;
}


.container .containDetails .divinputSecteur {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.container .containDetails .divinputSecteur label {
    margin: 0;
    color: #143D66;
    font-weight: 600
}

.container .containDetails .divinputSecteur div {
    width: 100%;
}

.container .containDetails .divinputSecteur input {
    border: none;
    margin-right: 10px;
    background-color: #fff;
    font-size: 15px;
    color: #143D66;
    text-align: center;
}

.container .containDetails .divinputSecteur button {
    text-decoration: none;
    background-color: #fff;
    border: none;
}