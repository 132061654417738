.container {
    width: 95%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    align-items: center;
    margin-bottom: 1rem;
}

.container .containDetails {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 0;
}
.container .containDetails .barre {
    height: 5px;
    width: 25px;
    border-radius: 5px;
    background: linear-gradient(90deg, #00ffba, #143d66);
    margin: 10px 0 10px 0;
}

.container .containDetails .containImg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;
}

.container .containDetails .containImg h5 {
    color: #143D66;
    margin-top: 1rem;
    font-weight: 600;
}

.container .containDetails .containImg p {
    margin: 0;
}

.container .containDetails .containImg p {
    color: #143D66;
}

.container .containDetails .containImg .href {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    outline: none;
    color: #143d66;
}
