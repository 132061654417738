.container {
    width: 95%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    align-items: center;
    margin: 1rem 0 1rem 0;
}

.container .containDetails {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 0;
}
.container .containDetails .barre {
    height: 5px;
    width: 25px;
    border-radius: 5px;
    background: #143d66;
    margin-bottom: 10px;
}

.container .containDetails .containImg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;
}

.container .containDetails .containImg .title {
    font-weight: 600;
    color: #143D66;
}

.container .containDetails .containImg .title span {
    color: red;
}

.container .containDetails .containImg p {
    margin: 0;
}


.container .containDetails .nameDegree{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.container .containDetails .nameDegree p {
    color: #143D66;
    margin: 0;
}

.weigth {
    font-weight: 600;
}