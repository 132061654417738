.container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #01E3A6;
    border-radius: 10px;
    padding: 0.6rem;
}


.container .containAll {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
}

.container .containAll .containDetails {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.container .containAll .containDetails .width {
    width: 100%;
}

.container .containAll .containDetails .title {
    font-size: 18px;
    color: #143D66;
    margin-top: 2rem;
}

.container .containAll .containDetails .title span {
    color: #143D66;
    font-weight: 700;
}

.container .containAll .containDetails .barre {
    height: 4px;
    width: 40px;
    border-radius: 5px;
    background: linear-gradient(90deg, #00ffba, #143d66);
    margin-bottom:2rem;
}

.container .containAll .containDetails .detailsMission {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container .containAll .containDetails .detailsMission .detail {
    width: 25%;
    display: flex;
    flex-direction: column;
    color: #143D66;
}

.container .containAll .containDetails .detailsMission .detail h6 {
    font-weight: 700;
}

.container .containAll .containDetails .detailsMissionLast {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.container .containAll .containDetails .detailsMissionLast .detail {
    width: 20%;
    display: flex;
    flex-direction: column;
    color: #143D66;
}

.container .containAll .containDetails .detailsMissionLast .detail h6 {
    font-weight: 700;
}

.container .containAll .containDetails h6 {
    color: #143D66;
    font-weight: 700;
    font-size: 18px;
}

.container .containAll .containDetails .para {
    color: #143D66;
}