.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container .button {
    width: 80%;
    margin: 5rem 0 2rem 0;
    display: flex;
    justify-content: space-between;
}

.container .button .button1 {
    border: none;
    background-color: red;
    outline: none;
    color: #fff;
    border-radius: 5px;
}

.container .button .button2 {
    border: none;
    background-color: #143D66;
    outline: none;
    color: #fff;
    border-radius: 5px;
}

.container .containAll {
    width: 80%;
    display: flex;
}

.container .containAll .colLeft {
    width: 25%;
    margin-bottom: 20px;
}

.container .containAll .colRight {
    width: 75%;
}

.hidden {
    display: none;
}