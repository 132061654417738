.section {
    display: flex;
    justify-content: center;
}


.section .container {
    width: 30%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px;
    margin: 75px 0 20px 0;
}

.section .container .title {
    color: #143D66;
    font-size: 25px;
    font-weight: 700;
}

.section .container .barre {
    height: 4px;
    width: 40px;
    border-radius: 5px;
    background: linear-gradient(90deg, #00ffba, #143d66);
    margin-bottom: 2rem;
}


.section .container .input {
    width: 90%;
    height: 40px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: #143D66 solid 0.5px;
    padding: 12px 20px;
}

.section .container .send {
    width: 30%;
    border: none;
    outline: none;
    border-radius: 4px;
    color: #143D66;
    background-color: #00ffba;
    height: 35px;
    font-size: 18px;
    margin-bottom: 10px;
}

.section .container .send:hover {
    cursor: pointer;
}


.errors {
    color: red;
    width: 70%;
}

@media(max-width: 900px) {
    .section .container {
        width: 70%;
    }
    .section .container .linkedin {
        width: 70%;
    }
}

@media(max-width: 700px) {
    .section .container {
        width: 80%;
    }
}

@media(max-width: 500px) {
    .section .container {
        width: 90%;
    }
}
