.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
}
.container .containProject {
    display: flex;
    width: 50%;
}

.container .containInput {
    margin: 200px 0 2rem 0;
}
.container .containInput label {
    margin-right: 20px;
}

.container .containInput input {
    margin-right: 20px;
}

.container .containInput button {
    outline: none;
    border: none;
    background-color: #00ffba;
    color: #143D66;
    border-radius: 5px;
    height: 30px;
    width: 80px;
}

.container .result {
    width: 100%;
    display: flex;
    justify-content: center;
}

.red {
    color: red;
}


