.container {
    width: 95%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    margin-top: 1rem;
}

.container .containDetails {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 0;
}

.container .containDetails .title {
    color: #143D66;
    font-weight: 600;
    font-size: 23px;
}

.container .containDetails .barre {
    height: 5px;
    width: 25px;
    border-radius: 5px;
    background: #143d66;
    margin-bottom: 2rem;
}

.container .containDetails .containImg {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.container .containDetails p {
    color: #143D66;
    font-size: 15px;
}

.container .containDetails .enterprise {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.container .containDetails .enterprise .title{
    color: #143D66;
    font-weight: 600;
    display: flex;
}
.container .containDetails .enterprise .title img{
    max-height: 23px;
    margin-right: 1rem;
}

.container .containDetails .enterprise .detailEnterprise {
    display: flex;
    justify-content: space-between;
}

.container .containDetails .enterprise .detailEnterprise h6 {
    color: #143D66;
}

.weight {
    font-weight: 600;
}

// CSS pour le form

.container .containDetails .secondTitle{
    font-size: 19px;
    font-weight: 600;
    color: #143d66;
    margin: 15px 0 15px 0;
}

.container .containDetails .input {
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
}

.container .containDetails .input label{
    width: 15%;
    color: #143d66;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
}
.container .containDetails .input input{
    width: 85%;
    padding: 12px 20px;
    border: #143D66 solid 0.5px;
    border-radius: 4px;
}

.container .containDetails .check {
    width: 100%;
    color: #143d66;
}


.container .containDetails .check input {
    margin-right: 15px;
}


.container .containDetails .date {
    display: flex;
    width: 100%;
    margin: 15px 0 15px 0;
}

.container .containDetails .date label {
    width: 10%;
    color: #143d66;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
}

.container .containDetails .date input {
    width: 35%;
    border: #143D66 solid 0.5px;
    border-radius: 4px;
    margin-right: 15px;
}

.container .containDetails .description {
    display: flex;
    flex-direction: column;
    color: #143D66;
}

.container .containDetails .description input {
    height: 80px;
    border-radius: 4px;
    border: #143D66 solid .5px;
    margin: 10px 0 10px 0;
    padding: 12px 20px;
}


.container .containDetails .buttonExperience {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 15px 0;
}

.container .containDetails .buttonExperience .btnSupp {
    text-decoration: none;
    border: 0;
    background-color: #fff;
    color: #143D66;
}

.container .containDetails .buttonExperience .btnExp {
    display: flex;
    justify-content: space-between;
}

.container .containDetails .buttonExperience .btnExp  .btnStop  {
    width: 110px;
    text-decoration: none;
    color: #143D66;
    background-color: #fff;
    border: solid #143D66 .5px;
    border-radius: 4px;
    margin-right: 15px;
}

.container .containDetails .buttonExperience .btnValid {
    width: 110px;
    color: #fff;
    background-color: #143D66;
    text-decoration: none;
    border: 0;
    border-radius: 4px;
}