.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.container .containMission {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2rem 0 2rem;
}

.container .containInput label {
    margin-right: 20px;
}

.container .containInput input {
    margin-right: 20px;
}

.container .containInput button {
    outline: none;
    border: none;
    background-color: #00ffba;
    color: #143D66;
    border-radius: 5px;
    height: 30px;
    width: 80px;
}

.container .result {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.hidden {
    display: none;
}