.header {
    width: 100%;
    height: 60px;
    background-color: #143D66;
    display: flex;
    justify-content: center;
}

.header .nav {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header .nav button {
    height: 34px;
    outline: none;
    background-color: #00ffba;
    color: #143D66;
    border: none;
    border-radius: 5px;
    width: 150px;
    font-weight: 600;
}

.hidden {
    display:none;
}