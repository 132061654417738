@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  font-family: 'Open Sans', sans-serif
}